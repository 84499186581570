import * as React from "react"
import Layout from "../layout/layout";
import {Link} from "gatsby";
import styled from "styled-components";
import OpenConfigurator from "../components/general/open-configurator";
import SEO from "../components/seo";

const StyledLink = styled(Link)`
  color: black;
  font-weight: bold;
`;

const HowToOrderPage = () => {
  return (
    <Layout>
      <SEO title={"Jak zamawiać"} />
      <h1>Jak zamawiać?</h1>
      <h2>
        Krok 1 - wybór produktu i jego specyfikacja
      </h2>
      <p>
        Otwórz <StyledLink to={"/configure"}>konfigurator</StyledLink>. Zaznacz wybrany produkt. Zostanie wyświetlona
        lista dostępnych opcji konfiguracji. Należy wybrać po jednej opcji z każdej kategorii (np. średnica, długość,
        itd.).
        Zostanie wtedy wyświetlona cena wybranej konfiguracji. Poniżej ceny należy potwierdzić dodatkowe atrybuty takie
        jak kolor oraz liczba sztuk, a następnie kliknąć przycisk "Dodaj do zamówienia".
      </p>
      <h2>
        Krok 2 - specyfikacja zamówienia
      </h2>
      <p>
        W widoku specyfikacji zamówienia zobaczysz dokładnie jakie wybrałeś produkty i w jakiej ilości.
        Możesz zmienić ilość produktów klikając na przyciski "+" i "-" odpowiadającym wybranej pozycji w zamówieniu.
        Klikając na ikonę kosza, usuniesz produkty z koszyka.
        Możesz również dodać kolejny produkt do zamówienia klikając na przycisk "Dodaj kolejny".
        <strong>W widoku specyfikacji zamówienia, koszt przesyłki nie jest uwzględniony. Wyliczamy go indywidualnie
          po złożeniu zamówienia.</strong>
      </p>
      <h2>
        Krok 3 - złożenie zamówienia
      </h2>
      <p>
        Wybierz czy chcesz złożyć zamówienia jako firma, czy osoba fizyczna. Następnie wpisz swoje dane w wyświetlonym
        formularzu.
        Kliknięcie przycisku "Wyślij" oznacza jedynie wysłanie specyfikacji zamówienia do naszej firmy. <strong>Po
        otrzymaniu
        przez nas Twojej specyfikacji, indywidualnie wyceniamy koszt wysyłki, a następnie odsyłamy fakturę pro forma.
        Po otrzymaniu płatności rozpoczynamy produkcję.</strong>
      </p>
      <OpenConfigurator />
    </Layout>
  )
}

export default HowToOrderPage
